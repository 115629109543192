import React from "react";
import PropTypes from "prop-types";
import AddReminderForm from "./AddReminderForm";
import EditReminderForm from "./EditReminderForm";
import Login from "./Login";
import { formatSpeed } from "../helpers";

class Inventory extends React.Component {
  static propTypes = {
    reminders: PropTypes.object,
    updateReminder: PropTypes.func,
    deleteReminder: PropTypes.func,
    loadSampleReminders: PropTypes.func,
    addReminder: PropTypes.func,
    setSpeed: PropTypes.func,
    speed: PropTypes.number
  };

  state = {
    showInventory: false
  };

  toggleInventory = () => {
    this.setState({ showInventory: !this.state.showInventory });
  };

  render() {
    const logout = <button onClick={this.props.logout}>Log out</button>;

    // 1. Check if they are logged in
    if (this.props.user === null || !this.props.user.uid) {
      return <Login authenticate={this.props.authenticate} />;
    }

    // 2. check if they are not the owner of the store
    if (this.props.user === null || 
      [this.props.user.developer, this.props.user.owner].indexOf(this.props.user.uid) === -1
    ) {
      return (
        <div className="floatingTopNav">
          <a href="/privacy-policy.html" className="privacy-link" target="_blank">Privacy Policy</a>
          <span className="red-text">Access Denied!</span>
          {logout}
        </div>
      );
    }

    // 3. They must be the owner, just render the inventory
    if (this.state.showInventory) {
      return (
        <div className="inventory">
          <button className="btn-close" onClick={this.toggleInventory}>
            X
          </button>
          <h4>Scroller speed - {formatSpeed(this.props.speed)} seconds</h4>
          <div className="scrollSpeed">
            <button onClick={() => this.props.setSpeed("slower")}>
              Slower
            </button>
            <button onClick={() => this.props.setSpeed("faster")}>
              Faster
            </button>
          </div>
          <h4>New reminder</h4>
          <AddReminderForm addReminder={this.props.addReminder} />
          <h4>Current reminders</h4>
          {Object.keys(this.props.reminders).map(key => (
            <EditReminderForm
              key={key}
              index={key}
              reminder={this.props.reminders[key]}
              updateReminder={this.props.updateReminder}
              deleteReminder={this.props.deleteReminder}
            />
          ))}
          <button
            className="sample-load"
            onClick={this.props.loadSampleReminders}
          >
            Load Sample Reminders
          </button>
        </div>
      );
    }

    return (
      <div className="floatingTopNav">
        <a href="/privacy-policy.html" className="privacy-link" target="_blank">Privacy Policy</a>
        <button onClick={this.toggleInventory}>Manage</button>
        {logout}
      </div>
    );
  }
}

export default Inventory;
