import React from "react";
import PropTypes from "prop-types";

class AddReminderForm extends React.Component {
  detailsRef = React.createRef();
  categoryRef = React.createRef();
  priorityRef = React.createRef();

  static propTypes = {
    addReminder: PropTypes.func
  };

  createReminder = event => {
    // 1.  stop the form from submitting
    event.preventDefault();
    const reminder = {
      details: this.detailsRef.current.value,
      category: this.categoryRef.current.value,
      priority: this.priorityRef.current.value,
      dateAdded: new Date().getTime()
    };
    this.props.addReminder(reminder);
    // refresh the form
    event.currentTarget.reset();
  };
  render() {
    return (
      <form className="reminder-form reminder-form-add" onSubmit={this.createReminder}>
        <textarea name="details" ref={this.detailsRef} placeholder="Enter reminder details" />

        <select name="category" ref={this.categoryRef}>
          <option value="general">General</option>
          <option value="raymond">Raymond</option>
          <option value="vincenzo">Vincenzo</option>
          <option value="zack">Zack</option>
        </select>

        <select name="priority" ref={this.priorityRef}>
          <option value="normal">Normal</option>
          <option value="high">High Priority</option>
        </select>

        <button type="submit">+ Add Reminder</button>
      </form>
    );
  }
}

export default AddReminderForm;
