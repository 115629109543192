import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../helpers";

class Reminder extends React.Component {
  static propTypes = {
    details: PropTypes.shape({
      details: PropTypes.string,
      category: PropTypes.string,
      priority: PropTypes.string,
      dateAdded: PropTypes.number,
      ready: PropTypes.bool
    }),
    addToOrder: PropTypes.func,
    index: PropTypes.string,
    updateReminder: PropTypes.func
  };

  toggleReady = event => {
    // update reminder ready value
    // 1. Take a copy of the current reminder
    var updatedReminder = { ...this.props.details };
    updatedReminder.ready =
      updatedReminder.ready === undefined || !updatedReminder.ready;
    this.props.updateReminder(this.props.index, updatedReminder);
  };

  render() {
    const {
      details,
      category,
      priority,
      dateAdded,
      ready
    } = this.props.details;

    const readyClass = ready ? "ready-yes" : "ready-no";
    const readyLabel = ready ? "READY" : "NOT READY";

    return (
      <div className={`reminder priority-${priority}`}>
        <span className="dateAdded">Added: {formatDate(dateAdded)}</span>
        <span className={`category category-${category}`}>{category}</span>
        {details}
        <button
          className={`ready-status ${readyClass}`}
          onClick={this.toggleReady}
        >
          {readyLabel}
        </button>
      </div>
    );
  }
}

export default Reminder;
