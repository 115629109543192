const reminders = {
  reminder01: {
    details: "Call Mr Mourinho",    
    category: "raymond",
    priority: "normal",
    dateAdded: 1570626838470
  },

  reminder02: {
    details: "Email client about broken LG panel",    
    category: "raymond",
    priority: "high",
    dateAdded: 1570626839255
  },

  reminder03: {
    details: "Deliver Bose subwoofer to 7, Old Trafford, Sir Matt Busby Way, Manchester",    
    category: "zack",
    priority: "normal",
    dateAdded: 1570626840014
  },

  reminder04: {
    details: "House repair for Mr Ferguson at 11am",    
    category: "vincenzo",
    priority: "normal",
    dateAdded: 1570626880464
  },
};

export default reminders;
