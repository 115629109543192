import React from "react";
import PropTypes from "prop-types";

class EditReminderForm extends React.Component {
  static propTypes = {
    reminder: PropTypes.shape({
      details: PropTypes.string,
      category: PropTypes.string,
      priority: PropTypes.string
    }),
    index: PropTypes.string,
    updateReminder: PropTypes.func
  };
  handleChange = event => {
    // update that reminder
    // 1. Take a copy of the current reminder
    const updatedReminder = {
      ...this.props.reminder,
      [event.currentTarget.name]:
        event.currentTarget.name === "price"
          ? parseFloat(event.currentTarget.value)
          : event.currentTarget.value
    };
    this.props.updateReminder(this.props.index, updatedReminder);
  };
  render() {
    const readyLabel = this.props.reminder.ready ? (
      <span className="readyLabel">Ready</span>
    ) : null;

    return (
      <div className="reminder-form">
        <textarea
          name="details"
          onChange={this.handleChange}
          value={this.props.reminder.details}
          placeholder="Enter reminder details"
        />

        <select
          name="category"
          onChange={this.handleChange}
          value={this.props.reminder.category}
        >
          <option value="general">General</option>
          <option value="raymond">Raymond</option>
          <option value="vincenzo">Vincenzo</option>
          <option value="zack">Zack</option>
        </select>

        <select
          name="priority"
          onChange={this.handleChange}
          value={this.props.reminder.priority}
        >
          <option value="normal">Normal</option>
          <option value="high">High Priority</option>
        </select>

        <div className="buttonHolder">
          {readyLabel}
          <button onClick={() => this.props.deleteReminder(this.props.index)}>
            Delete
          </button>
        </div>
      </div>
    );
  }
}

export default EditReminderForm;
