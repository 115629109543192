import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import Inventory from "./Inventory";
import sampleReminders from "../sample-reminders";
import Reminder from "./Reminder";
import base, { firebaseApp } from "../base";

class App extends React.Component {
  state = {
    reminders: {},
    category: "",
    speed: 0,
    user: null
  };

  appId = "prod";

  static propTypes = {
    match: PropTypes.object
  };

  authHandler = async authData => {
    // 1 .Look up the current store in the firebase database
    const store = await base.fetch(this.appId, { context: this });

    // 2. If developer and owner are not set, set and save. First to login is developer, second to login is owner.
    var settingDeveloper = false;
    if (!store.developer) {
      // first user to login becomes developer
      await base.post(`${this.appId}/developer`, {
        data: authData.user.uid
      });
      settingDeveloper = true;
    } else {
      // second user to login becomes owner
      if (store.developer !== authData.user.uid && !store.owner) {
        await base.post(`${this.appId}/owner`, {
          data: authData.user.uid
        });
      }
    }

    // 3. Set the state of the inventory component to reflect the current user
    this.setState({
      user: {
        uid: authData.user.uid,
        developer: store.developer || authData.user.uid,
        owner: store.owner || (!settingDeveloper ? authData.user.uid : null)
      }
    });
  };

  authenticate = provider => {
    const authProvider = new firebase.auth[`${provider}AuthProvider`]();
    firebaseApp
      .auth()
      .signInWithPopup(authProvider)
      .then(this.authHandler)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        console.log(errorCode);
        alert(errorCode);

        var errorMessage = error.message;
        console.log(errorMessage);
        alert(errorMessage);
      });
  };

  logout = async () => {
    await firebase.auth().signOut();
    this.setState({ user: null });
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ category: params.category });

    this.ref = base.syncState(`${this.appId}/reminders`, {
      context: this,
      state: "reminders"
    });

    this.ref2 = base.syncState(`${this.appId}/speed`, {
      context: this,
      state: "speed"
    });

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.authHandler({ user });
      }
    });
  }

  componentWillUnmount() {
    base.removeBinding(this.ref);
    base.removeBinding(this.ref2);
  }

  addReminder = reminder => {
    // 1. Take a copy of the existing state
    const reminders = { ...this.state.reminders };
    // 2. Add our new reminder to that reminders variable
    reminders[`reminder${Date.now()}`] = reminder;
    // 3. Set the new reminders object to state
    this.setState({ reminders });
  };

  updateReminder = (key, updatedReminder) => {
    // 1. Take a copy of the current state
    const reminders = { ...this.state.reminders };
    // 2. Update that state
    reminders[key] = updatedReminder;
    // 3. Set that to state
    this.setState({ reminders });
  };

  deleteReminder = key => {
    if (window.confirm("Are you sure you want to delete this reminder?")) {
      // 1. take a copy of state
      const reminders = { ...this.state.reminders };
      // 2. update the state
      reminders[key] = null;
      // 3.  update state
      this.setState({ reminders });
    }
  };

  loadSampleReminders = () => {
    this.setState({ reminders: sampleReminders });
  };

  switchCategory = event => {
    var category = event.currentTarget.value;
    this.props.history.push(`/${category}`);
    this.setState({ category });
  };

  setSpeed = mode => {
    var speed = parseFloat(this.state.speed);
    speed = isNaN(speed) ? 4000 : speed;
    speed += 500 * (mode === "slower" ? 1 : -1);
    speed = speed < 500 ? 500 : speed;
    this.setState({ speed: parseFloat(speed) });
  };

  filterReminders = () => {
    var filtered = {};
    var stateReminders = this.state.reminders;
    var stateCategory = this.state.category;
    var stateUser = this.state.user;

    Object.keys(stateReminders).forEach(function(key) {
      if (
        stateCategory === undefined ||
        stateCategory === "" ||
        stateCategory === stateReminders[key].category
      ) {
        if (
          stateUser !== null &&
          [stateUser.developer, stateUser.owner].indexOf(stateUser.uid) > -1
        ) {
          // Logged in = show all
          filtered[key] = stateReminders[key];
        } else {
          if (["vincenzo", "zack"].indexOf(stateReminders[key].category) > -1) {
            // Logged out = show Vincenzo and Zack only
            filtered[key] = stateReminders[key];
          }
        }
      }
    });

    return filtered;
  };

  render() {
    return (
      <div className="rv-reminders">
        <div className="reminders-slider">
            {Object.keys(this.filterReminders()).map(key => {
              return (
                <Reminder
                  key={key}
                  index={key}
                  details={this.state.reminders[key]}
                  updateReminder={this.updateReminder}
                />
              );
            })}
        </div>
        <Inventory
          addReminder={this.addReminder}
          updateReminder={this.updateReminder}
          deleteReminder={this.deleteReminder}
          loadSampleReminders={this.loadSampleReminders}
          setSpeed={this.setSpeed}
          speed={this.state.speed}
          reminders={this.state.reminders}
          appId={this.appId}
          authenticate={this.authenticate}
          logout={this.logout}
          user={this.state.user}
        />
        <div className="category-filter">
          <select
            name="category-filter"
            onChange={this.switchCategory}
            value={this.state.category}
          >
            <option value="">All reminders</option>
            <option value="general">General only</option>
            <option value="raymond">Raymond only</option>
            <option value="vincenzo">Vincenzo only</option>
            <option value="zack">Zack only</option>
          </select>
        </div>
      </div>
    );
  }
}

export default App;
